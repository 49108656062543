.grid-item img {
  border-radius: 10px;
  min-height: 200px;
}

.grid-item .grid-item__Image {
  min-height: 200px;
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
  background-color: #E2E8F0;
}

.link-overlay {
  position:static!important;
}

.link-overlay:before {
  content: '';
  cursor: inherit;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.bg-static {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: top left;
  background-repeat:no-repeat;
  position:fixed;
}

.m-plus {
  font-family: 'M PLUS Rounded 1c', sans-serif!important;
}